<template>
  <div class="app">
    <div class="common-content">
      <el-row :gutter="0" style="width:100%;height:100%"><!--v-if="isshow" @click.native="changer()" -->
        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOutLeft" v-show="isshow">
          <el-col :xs="8" :sm="5" :md="5" :lg="5" :xl="5" v-if="isshow" class="sidebar animated">
            <div class="grid-content bg-purple" @click="changer($event)">
              <img src="../../assets/images/ceo-pic.png" alt>
              <h4>{{ info.nick_name }}</h4>
              <p>
                账号：
                <span>{{ info.mobile }}</span>
              </p>
              <el-menu>
                <template v-for="router in defaultLeftMenu">
                  <router-link :to='{ name: router.routerName }' active-class="active" :key="router.index">
                    <el-menu-item :index="router.index">
                      <svg class="icon" aria-hidden="true">
                        <use :xlink:href="router.svgClass"></use>
                      </svg>
                      <template v-if="router.routerName == 'Tenants'">
                        <span v-if='info.store !== "" && store.status <= 0 || !info.store'>申请入驻</span>
                        <span v-if='info.store !== "" && store.status > 0'>入驻信息</span>
                      </template>
                      <template v-else>
                        <span slot="title">{{ router.label }}</span>
                      </template>
                    </el-menu-item>
                  </router-link>
                </template>
                <!----->
                <div v-if='info.store !== "" && store.status > 0'>
                  <template v-for="router in leftMenu">
                    <router-link :to='{ name: router.routerName }' active-class="active" :key="'l' + router.index">
                      <el-menu-item :index="router.index">
                        <svg class="icon" aria-hidden="true">
                          <use :xlink:href="router.svgClass"></use>
                        </svg>
                        <span>{{ router.label }}</span>
                      </el-menu-item>
                    </router-link>
                  </template>
                </div>
                <div v-else>
                  <template v-for="router in elseMenu">
                    <router-link :to='{ name: router.routerName }' active-class="active" :key="'e' + router.index">
                      <el-menu-item :index="router.index">
                        <svg class="icon" aria-hidden="true">
                          <use :xlink:href="router.svgClass"></use>
                        </svg>
                        <span>{{ router.label }}</span>
                      </el-menu-item>
                    </router-link>
                  </template>
                </div>
              </el-menu>
              <!-- <el-menu>
                <router-link :to='{ name: "UserHome" }' active-class="active">
                  <el-menu-item index="2">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-ziyuan17"></use>
                    </svg>
                    <span slot="title">我的主页</span>
                  </el-menu-item>
                </router-link>
                <router-link :to='{ name: "Ipoints" }' active-class="active">
                  <el-menu-item index="3">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-ziyuan16"></use>
                    </svg>
                    <span slot="title">我的积分</span>
                  </el-menu-item>
                </router-link>
                <router-link :to='{ name: "Transactions" }' active-class="active">
                  <el-menu-item index="4">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-ziyuan15"></use>
                    </svg>
                    <span slot="title">交易记录</span>
                  </el-menu-item>
                </router-link>
                <router-link :to='{ name: "UserInfo" }' active-class="active">
                  <el-menu-item inex="1">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-ziyuan14"></use>
                    </svg>
                    <span slot="title">我的信息</span>
                  </el-menu-item>
                </router-link>
                <router-link :to='{ name: "Updatepwd" }' active-class="active">
                  <el-menu-item inex="5">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-jiesuo"></use>
                    </svg>
                    <span slot="title">修改密码</span>
                  </el-menu-item>
                </router-link>
                <router-link :to='{ name: "Tenants" }' active-class="active" v-if="info.is_staff === 0">
                  <el-menu-item index="6">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-ziyuan12"></use>
                    </svg>
                    <span v-if='info.store !== "" && store.status <= 0 || !info.store'>申请入驻</span>
                    <span v-if='info.store !== "" && store.status > 0'>入驻信息</span>
                  </el-menu-item>
                </router-link>
                <div v-if='info.store !== "" && store.status > 0'>
                  <router-link :to='{ name: "Viewrecord" }' active-class="active">
                    <el-menu-item index="7">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-ziyuan13"></use>
                      </svg>
                      <span>观看记录</span>
                    </el-menu-item>
                  </router-link>
                  <router-link :to='{ name: "Eviewrecord" }' active-class="active">
                    <el-menu-item index="8" class="el-menu-item-read">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-ziyuan12"></use>
                      </svg>
                      <span>员工观看记录</span>
                    </el-menu-item>
                  </router-link>
                  <router-link :to='{ name: "text" }' active-class="active">
                    <el-menu-item index="9" class="el-menu-item-read">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-ziyuan12"></use>
                      </svg>
                      <span>我的测评</span>
                    </el-menu-item>
                  </router-link>
                  <router-link :to='{ name: "Department" }' active-class="active">
                    <el-menu-item index="10">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-ziyuan13"></use>
                      </svg>
                      <span>部门管理</span>
                    </el-menu-item>
                  </router-link>
                  <router-link :to='{ name: "Employees" }' active-class="active">
                    <el-menu-item index="11">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-ziyuan18"></use>
                      </svg>
                      <span>员工管理</span>
                    </el-menu-item>
                  </router-link>
                  <router-link :to='{ name: "Roles" }' active-class="active">
                    <el-menu-item index="12">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-ziyuan14"></use>
                      </svg>
                      <span>角色管理</span>
                    </el-menu-item>
                  </router-link>
                </div>
              </el-menu> -->

            </div>
          </el-col>
        </transition>
        <el-col :xs="16" :sm="19" :md="19" :lg="19" :xl="19" class="personal-content">
          <div class="grid-content bg-purple-light">
            <i class="el-icon-menu menu" @click="showBar()" v-show="!isshow"></i>
            <router-view></router-view>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
      store: {},
      isshow: false,
      clientWidth: 0,
      defaultLeftMenu: [
        { label: "我的主页", svgClass: "#icon-ziyuan17", routerName: "UserHome", index: "2" },
        { label: "我的测评", svgClass: "#icon-xuankeceping", routerName: "UserExams", index: "3" },
        { label: "我的积分", svgClass: "#icon-ziyuan16", routerName: "Ipoints", index: "4" },
        { label: "交易记录", svgClass: "#icon-ziyuan15", routerName: "Transactions", index: "5" },
        { label: "我的信息", svgClass: "#icon-ziyuan14", routerName: "UserInfo", index: "6" },
        { label: "修改密码", svgClass: "#icon-mima", routerName: "Updatepwd", index: "7" },
        { label: "入驻信息", svgClass: "#icon-ziyuan12", routerName: "Tenants", index: "8" },
      ],
      leftMenu: [
        { label: "观看记录", svgClass: "#icon-guankan", routerName: "Viewrecord", index: "9" },
        { label: "员工测评记录", svgClass: "#icon-dianshizhiboguankanshipinbofangmeiti", routerName: "Viewtest", index: "10" },
        { label: "员工观看记录", svgClass: "#icon-dianshizhiboguankanshipinbofangmeiti", routerName: "Eviewrecord", index: "11" },
        { label: "部门管理", svgClass: "#icon-ziyuan13", routerName: "Department", index: "12" },
        { label: "员工管理", svgClass: "#icon-yuangongguanli2", routerName: "Employees", index: "13" },
        { label: "角色管理", svgClass: "#icon-jiaoseguanli", routerName: "Roles", index: "14" },
      ],
      elseMenu: [
        { label: "员工测评记录", svgClass: "#icon-dianshizhiboguankanshipinbofangmeiti", routerName: "Viewtest", index: "10" },
        { label: "员工观看记录", svgClass: "#icon-dianshizhiboguankanshipinbofangmeiti", routerName: "Eviewrecord", index: "11" },
      ]
    }
  },
  methods: {

    changer(e) {
      let currentTag = e.target.closest('li')
      if (currentTag) {
        for (let ind in currentTag.children) {
          let list = currentTag.children[ind]
          if (list.nodeName === 'UL') {
            this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
            if (this.clientWidth < 700) {
              this.isshow = true
              break
            }
          } else {
            this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
            if (this.clientWidth < 700) {
              this.isshow = false
            }
          }
        }
      }
      // else {
      //   this.isshow = false
      // }
    },
    getInfo() {
      this.$store.dispatch('getInfo').then(res => {
        // console.log(res)
        this.info = res.info
        // this.$store.getters('getUserId', res.info.id)
        if (res.info.store) {
          this.store = res.info.store
          // console.log('store:', res.info)
        }
      })
    },
    // 侧边栏
    showBar() {
      this.isshow = !this.isshow
    }
  },
  mounted() {
    this.getInfo()
    this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
    if (this.clientWidth > 700) {
      this.isshow = true
    }
  }
}
</script>
<style lang="less" scoped>
.app {
  background: @global-personal-background;
  padding: 50px 0;

  .icon {
    width: 20px;
    height: 20px;
    fill: #555;
    margin-right: .5rem
  }

  .common-content {
    width:95%;
    margin:0 auto;
    background: #fff;
    box-shadow: 2px 5px 20px @global-gray;

    .bg-purple {
      background: @global-personal-background;
      padding-top: 20px;
      text-align: center;

      img {
        width: 80px;
        height: 80px;
      }

      .el-menu {
        border-right: none;
        margin-top: 20px;
        background-color: @global-personal-background;
        overflow: hidden;

        .el-menu-item-read {
          padding-left: 50px !important
        }

        .active {
          display: -webkit-box;
          border-bottom: none;

          .el-menu-item {
            width: 100%;
            color: @global-head-active-color;
            background: @global-white;

            .icon {
              fill: @global-head-active-color
            }
          }

          .el-menu-item-group {
            .el-menu-item {
              .icon {
                fill: #555 !important
              }
            }
          }

          .el-submenu {
            width: 100%;
            background: @global-white;

            .el-submenu__title {
              .icon {
                fill: @global-head-active-color;
              }

              span {
                color: @global-head-active-color;
              }
            }

            .el-menu-item-group {
              .el-menu-item {
                color: #555
              }
            }
          }
        }

        .is-active {
          color: #555;
        }
      }
    }

    .bg-purple-light {
      background: @global-white;
      min-height: 735px
    }
  }
}

@media screen and (max-width: 700px) {
  .sidebar {
    // display: none
    width: 50%;
    position: fixed;
    z-index: 999;
    height: 100%;
    top: 0;

    .bg-purple {
      height: 100%;

      img {
        width: 50px !important;
        height: 50px !important
      }

      h4,
      p {
        margin: 10px 0
      }
    }
  }

  .personal-content {
    width: 100%
  }

  .grid-content {
    text-align: left;
  }

  .menu {
    font-size: 2rem;
    margin-left: 0.3rem;
    margin-top: 1rem;

  }

}
</style>
